import React from 'react';
import { compose } from 'recompose';

import DashboardWrapper from '../../../components/Dashboard/DashboardWrapper';
import Layout from '../../../components/layout';
import { withAuthorization } from '../../../components/Session';
import HeadData from '../../../data/HeadData';
import BackButton from '../../../components/Common/BackButton';
import MARCSectionUpdate from '../../../components/Sections/MARCSectionUpdate';

const MARCPageBase = () => (
  <DashboardWrapper pageName="Medical Allied Reinsurance Company (MARC)">
    <div className="relative pt-16 pb-32 md:pt-20 md:px-8 px-0">
      <div className="container mx-auto px-4">
        <MARCSectionUpdate />

        <BackButton buttonLink="/sales-support-documents/collateral" />
      </div>
    </div>
  </DashboardWrapper>
);

const condition = (authUser) => !!authUser;

const MARCPage = compose(withAuthorization(condition))(
  MARCPageBase,
);

const MARC = () => (
  <Layout>
    <HeadData title="Medical Allied Reinsurance Company (MARC)" />
    <MARCPage />
  </Layout>
);

export default MARC;
